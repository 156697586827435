// ------------------------------------------------deprecated--------------------------------------------------//
//direct cloud file uoload with signed url
// this file handle everything related to cloud file upload
// import thius file and call "uploadfile" method with parameters file object, the resource url, and the is of resource we want to attach file
// if we uploading exam answer attachment then id will be examanswer id / if assignmentanswer then assignmne tanswe id
import axios from "axios";
// import VueToast from 'vue-toast-notification';
// import store from "../store";

let cloudFileUpload = {
  async uploadfile(file, url, attachmentModelId) {
      let fileAttribuits = {};
      fileAttribuits.uploadPercentage = 0;
    fileAttribuits = await this.getSignedUrl(file, url, fileAttribuits);
    
      if (!fileAttribuits) return false; // error
    fileAttribuits = await this.uploadToCloud(file, fileAttribuits);
    

      if (!fileAttribuits) return false; // error

      fileAttribuits.mime = file.type;
      fileAttribuits.size_byte = file.size;
      fileAttribuits.model_id = attachmentModelId;

    fileAttribuits = await this.saveToDatabase(url, fileAttribuits);
    

  if (!fileAttribuits) return false; // error
  return true; // success
  },

  async getSignedUrl(file, url, fileAttribuits) {

      // var uploadstatus = 0;
      // let url = store.getters.getAPIKey.examAnswers;
    fileAttribuits.uploadPercentage = 1;
    let returnResponse = null;
      await axios
        .post(url + "/attachment/signedurl", { file_name: file.name })
        .then((response) => {
          fileAttribuits.uploadPercentage = 5;
          
            fileAttribuits.file_name = response.data.file_name;
            fileAttribuits.signed_url = response.data.signed_url;
            returnResponse = fileAttribuits;
          //   fileAttribuits = response.data; // {file_name: "", signed_url:""}

        })
        .catch((error) => {
          // VueToast.toast.error(error.response.data.message);
          alert("getSignedUrl error");
          error;
          returnResponse = false;
        });
        return returnResponse;
  },

  async uploadToCloud(file, fileAttribuits) {
    let returnResponse = null;
      let uploadProgress;
      var config = {
        headers: { "Content-Type": file.type },
        onUploadProgress: function(progressEvent) {
          uploadProgress = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
          fileAttribuits.uploadPercentage = uploadProgress;
        }.bind(this),
      };
      await axios
        .put(fileAttribuits.signed_url, file, config)
        .then((response) => {
          fileAttribuits.uploadPercentage = 95;
            response;
            returnResponse = fileAttribuits;
        })
          .catch((error) => {

            // VueToast.error(error.response.data.message);
            alert("uploadToCloud error");
          error;
          returnResponse = false;
        });
        return returnResponse;
  },

  async saveToDatabase(url, fileAttribuits) {
    let returnResponse = null;

        await axios
          .post(url + "/attachment/savefile", fileAttribuits)
          .then((response) => {
            fileAttribuits.uploadPercentage = 100;
            returnResponse = response.data;
          })
            .catch((error) => {

              // VueToast.error(error.response.data.message);
              alert("saveToDatabase error");
          error;
              returnResponse = false;
            });
    return returnResponse;
  },

};

export default cloudFileUpload;
