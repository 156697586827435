<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-container v-else>
      <br />
      <b-row>
        <b-col md="12">
          <!-- <p>{{assignmentAnswer}}</p> -->
          <b-modal
            id="finishExam"
            no-close-on-backdrop
            no-close-on-keyboard
            no-close-on-esc
            hide-header-close
            hide-header
            hide-footer
            centered
            size="sm"
          >
            <p class="text-dark" style="font-size: 18px">
              Are you sure you want to end the assignment?
            </p>
            <div class="acceptOrNot">
              <b-button
                variant="primary"
                size="md"
                @click="$router.push({ name: 'institute' })"
                pill
                >Yes</b-button
              >
              <b-button
                variant="danger"
                class="ml-3"
                size="md"
                @click="$bvModal.hide('finishExam')"
                pill
                >No</b-button
              >
            </div>
          </b-modal>
          <h3 class="text-center">Answers for {{ exam.name }}</h3>
          <div class="finishExam text-right">
            <b-button
              variant="danger"
              size="md"
              @click="$bvModal.show('finishExam')"
              >Finish Assignment</b-button
            >
          </div>
          <div class="answersToQuestions">
            <br />
            <div
              v-for="(answer, answerIndex) in answers"
              :key="answerIndex"
              class="answers"
            >
              <b-card no-body header-tag="header" footer-tag="footer">
                <template #header>
                  <div
                    class="question d-flex justify-content-between align-items-baseline"
                  >
                    <p class="text-dark">
                      {{
                        answer.questionNumber != ""
                          ? answer.questionNumber + ": "
                          : ""
                      }}{{ answer.question }}
                    </p>
                    <span
                      class="text-right btn p-0 m-0 text-info"
                      v-if="answer.disabled"
                      @click="answer.disabled = false"
                      ><i class="fas fa-edit"></i
                    ></span>
                  </div>
                  <div v-if="answer.files.length != 0" class="text-right">
                    <p class="text-dark">
                      {{
                        answer.files.length > 1
                          ? "Question Attachments:"
                          : "Question Attachment:"
                      }}
                    </p>
                    <span
                      class="text-dark ml-3"
                      v-for="(file, attaachmentIndex) in answer.files"
                      :key="attaachmentIndex"
                    >
                      <a
                        target="_blank"
                        c
                        :href="
                          userAttachments + file.file_path + file.file_name
                        "
                        class="text-decoration-none text-left"
                      >
                        {{ file.file_name }}
                      </a>
                    </span>
                  </div>
                </template>
              </b-card>
            </div>
          </div>
          <br />
          <hr style="background-color: #0275d8; height: 2px" />
          <div class="assignmentAnswers">
            <b-form>
              <div class="d-flex justify-content-between">
                <h3>Answer</h3>
                <span
                  class="btn m-0 p-0 text-info"
                  @click="assignmentAnswer.disabled = false"
                  v-if="assignmentAnswer.disabled"
                  ><i class="fas fa-edit"></i
                ></span>
              </div>

              <div class="answer">
                <b-textarea
                  v-model="assignmentAnswer.answer"
                  placeholder="Write answer here..."
                  :disabled="assignmentAnswer.disabled"
                  :class="{ 'is-invalid': answerError.answer }"
                ></b-textarea>
                <span class="text-danger" v-if="answerError.answer">{{
                  answerError.answer[0]
                }}</span>

                <span class="text-danger" v-if="responseErrors.answer">{{
                  responseErrors.answer[0]
                }}</span>
                <span class="text-danger" v-if="responseErrors.attachment">{{
                  responseErrors.attachment[0]
                }}</span>

                <b-form-file
                  ref="attachAssignment"
                  multiple
                  v-model="assignmentAnswer.attachment"
                  style="display: none"
                ></b-form-file>
                <div class="text-right mt-3">
                  <span
                    class="text-info btn p-0 m-0"
                    @click="attachAnswer()"
                    v-if="!assignmentAnswer.disabled"
                    ><i class="fas fa-paperclip fa-2x"></i
                  ></span>
                  <div class="d-flex justify-content-between">
                    <div class="text-left">
                      <span
                        class="text-dark ml-3"
                        v-for="(file,
                        attaachmentIndex) in assignmentAnswer.files"
                        :key="attaachmentIndex"
                      >
                        <div class="d-flex align-items-baseline">
                          <a
                            :href="
                              userAttachments + file.file_path + file.file_name
                            "
                            target="_blank"
                            class="text-decoration-none"
                            >{{ file.file_name }}</a
                          >
                          <span
                            v-if="!assignmentAnswer.disabled"
                            class="btn p-0 m-0 ml-2 text-danger"
                            @click="deleteStudentAttachment(file.id)"
                            ><i class="fas fa-trash"></i
                          ></span>
                        </div>

                        <br />
                      </span>
                    </div>
                    <div v-if="assignmentAnswer.attachment.length != 0">
                      <span
                        class="text-dark ml-3"
                        v-for="(file,
                        attaachmentIndex) in assignmentAnswer.attachment"
                        :key="attaachmentIndex"
                      >
                        <span>
                          {{ file.name
                          }}<span
                            class="btn p-0 m-0 ml-2 text-danger"
                            @click="
                              assignmentAnswer.attachment.splice(
                                attaachmentIndex,
                                1
                              )
                            "
                            ><i class="fas fa-trash"></i
                          ></span>
                          <br />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <b-form-group>
                <b-button
                  variant="primary"
                  v-if="!assignmentAnswer.disabled"
                  @click="createAnswers()"
                  :disabled="answerBtn.disabled"
                  >{{ answerBtn.text }}</b-button
                >
              </b-form-group>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import cloudFileUpload from "../../../cloudFileUpload.js";
export default {
  created() {
    this.fetchData = true;
    this.getExam();
    this.userAttachments = this.$store.getters.getAPIKey.userAttachments;
  },
  data() {
    return {
      fetchData: false,
      assignmentAnswer: {
        answer: "",
        attachment: [],
        files: [],
        score: "",
        disabled: false,
      },
      userAttachments: "",
      exam: {},
      examQuestions: [],
      answers: [],
      responseErrors: {},
      responseSuccess: {},
      answerBtn: {
        text: "Submit",
        disabled: false,
      },
      answerError: {},
    };
  },
  methods: {
    async prepareAttachment(assignmentAttachment, answerId) {
      let answer_Id = answerId;
      await assignmentAttachment.forEach((answer, index) => {
        let file = answer;
        this.uploadfile(file, answer_Id, index);
      });
    },
    async uploadfile(file, examQuestionId, index) {
      let url = this.$store.getters.getAPIKey.assignmentAnswers;
      let uploadSucces = await cloudFileUpload.uploadfile(
        file,
        url,
        examQuestionId
      );
      if (!uploadSucces)
        this.$toast.error(
          "Something went wring when upload file, please try again"
        );
      // let fileAttribuits = {};
      // fileAttribuits.uploadPercentage = 0;
      // // var uploadstatus = 0;
      // let url = this.$store.getters.getAPIKey.assignmentAnswers;
      // fileAttribuits.uploadPercentage = 1;
      // await axios
      //   .post(url + "/attachment/signedurl", { file_name: file.name })
      //   .then((response) => {
      //     fileAttribuits.uploadPercentage = 5;
      //     fileAttribuits = response.data;
      //   })
      //   .catch((error) => {
      //   });
      // let uploadProgress;
      // var config = {
      //   headers: { "Content-Type": file.type },
      //   onUploadProgress: function(progressEvent) {
      //     uploadProgress = parseInt(
      //       Math.round((progressEvent.loaded / progressEvent.total) * 100)
      //     );
      //     fileAttribuits.uploadPercentage = uploadProgress;
      //   }.bind(this),
      // };
      // await axios
      //   .put(fileAttribuits.signed_url, file, config)
      //   .then((response) => {
      //     fileAttribuits.uploadPercentage = 95;
      //   })
      //   .catch((error) => {
      //     return false;
      //   });
      // fileAttribuits.mime = file.type;
      // fileAttribuits.size_byte = file.size;
      // fileAttribuits.assignmentresponse_id = examQuestionId;

      // await axios
      //   .post(url + "/attachment/savefile", fileAttribuits)
      //   .then((response) => {
      //     fileAttribuits.uploadPercentage = 100;

      if (uploadSucces) {
        if (index == this.assignmentAnswer.attachment.length - 1) {
          this.responseSuccess.created = ["Answer Submitted."];
          this.getExamQuestions();
          this.clearResponse();
        }
      }
      //     return response.data;
      //   })
      //   .catch((error) => {
      //   });
    },
    onFileChange() {
      // let formName = document.forms["uploadFile"]["fileName"].files[0];
      // let files = e.target.files || e.dataTransfer.files;
      // if (!files.length) return;
    },
    checkFile() {
      // e;
    },
    createAnswers() {
      if (this.assignmentAnswer.answer == "") {
        this.answerError["answer"] = ["Answer is mandatory!"];
        this.$forceUpdate();
        return setTimeout(() => {
          this.answerError = {};
        }, 3000);
      }
      this.answerBtn.text = "Please wait...";
      this.answerBtn.disabled = true;
      const url = this.$store.getters.getAPIKey.assignmentAnswers;

      const answer = {
        answer: this.assignmentAnswer.answer,
        assignment_id: this.exam.id,
      };
      axios
        .post(url, answer)
        .then((response) => {
          if (this.assignmentAnswer.attachment.length != 0) {
            this.prepareAttachment(
              this.assignmentAnswer.attachment,
              response.data.id
            );
          }
          if (this.assignmentAnswer.attachment.length == 0) {
            this.responseSuccess.created = ["Answer Submitted."];
            this.getExamQuestions();
            this.clearResponse();
          }
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
        });
    },
    getExamQuestions() {
      const url =
        this.$store.getters.getAPIKey.assignment +
        `/${this.exam.id}/assignmentquestions`;
      axios.get(url).then((response) => {
        this.examQuestions = response.data;
        if (this.examQuestions.length != 0) {
          this.answers = [];
          this.examQuestions.forEach((answer) => {
            this.answers.push({
              questionNumber: answer.question_no,
              question: answer.name,
              attachment: [],
              files: answer.files,
              studentFiles: [],
            });
          });
        }
        this.getAnswers();
        // this.fetchData = false;
        this.$forceUpdate();
      });
    },
    getAnswers() {
      this.$forceUpdate();
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/assignments/${this.exam.id}/institutionuser/assignmentresponses`;
      axios.get(url).then((response) => {
        this.assignmentAnswer.attachment = [];
        this.assignmentAnswer.files = [];
        const answer = response.data;
        if (answer != "") {
          this.assignmentAnswer.answer = answer.answer;
          this.assignmentAnswer.files = answer.files;
          this.assignmentAnswer.score = answer.score;
          this.assignmentAnswer.disabled = true;
        }
        this.fetchData = false;
        // this.$forceUpdate();
      });
    },

    getExam() {
      const assignmentId = this.$route.params.assignment_id;
      const url = this.$store.getters.getAPIKey.assignment + `/${assignmentId}`;
      axios.get(url).then((response) => {
        this.exam = {};
        this.exam = response.data;
        this.getExamQuestions();
      });
    },

    clearResponse() {
      this.answerBtn.text = "Submit";
      setTimeout(() => {
        if (this.responseSuccess.created) {
          this.responseSuccess = {};
          this.responseSuccess = {};
          this.$forceUpdate();
        } else {
          this.responseErrors = {};
          this.$forceUpdate();
        }
        this.answerBtn.disabled = false;
      }, 3000);
    },
    attachAnswer() {
      //   let attachedAnswer = `${"attachAnswer" + answer}`;
      this.$refs.attachAssignment.$refs.input.click();
    },
    async deleteStudentAttachment(fileId) {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/assignmentresponses/${this.exam.id}/attachment/${fileId}`;
      await this.$axios
        .delete(url)
        .then(() => {
          this.$toast.success("Attachment Deleted", {
            position: "top",
            duration: 3000,
          });
          this.getExamQuestions();
        })
        .catch(() => {
          this.$toast.error("Something went wrong, Please try again!", {
            position: "top",
            duration: 3000,
          });
        });
    },
  },
};
</script>
